var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "lawer_lvsuo" },
    [
      _c(
        "div",
        { staticClass: "lawer_content" },
        [
          _c("div", { staticClass: "ShaiXuan" }, [
            _c("i", {
              staticClass: "iconfont icon-back",
              on: { click: _vm.goback },
            }),
            _c("div", [
              _c(
                "span",
                {
                  on: {
                    click: function ($event) {
                      _vm.show = true
                    },
                  },
                },
                [_vm._v("筛选")]
              ),
            ]),
          ]),
          _c("ul", { staticClass: "portrait" }, [
            _c("li", [
              _c("img", {
                staticStyle: {
                  width: "100%",
                  height: "100%",
                  "border-radius": "50%",
                },
                attrs: { src: this.$store.state.user.userInfo.avatarUrl },
              }),
            ]),
            _c("li", [
              _c("div", [
                _c("span", [_vm._v(_vm._s(this.$route.query.name))]),
                _c("span", [_vm._v("律师")]),
              ]),
              _c("div", [
                _c("span", [
                  _vm._v("执业证号：" + _vm._s(this.lawer.jobNumber)),
                ]),
              ]),
            ]),
          ]),
          _c("ul", { staticClass: "portrait_lawer" }, [
            _c("li", [
              _c("span", [_vm._v(_vm._s(this.$route.query.lawFirmName))]),
            ]),
            _c("li", [
              _c("div", [
                _c("i", { staticClass: "iconfont icon-susongguanli" }),
                _c("p", { staticClass: "SuSong" }, [_vm._v("诉讼")]),
                _c("p", [_vm._v(_vm._s(this.lawer.superiorityNum) + "件")]),
              ]),
              _c("div", [
                _c("i", { staticClass: "iconfont icon-working-year" }),
                _c("p", { staticClass: "SuSong" }, [_vm._v("经验")]),
                _c("p", [
                  _vm._v(
                    _vm._s(this.lawer.Particular ? this.lawer.Particular : 0) +
                      "年"
                  ),
                ]),
              ]),
              _c("div", [
                _c("i", { staticClass: "iconfont icon-xueli" }),
                _c("p", { staticClass: "SuSong" }, [_vm._v("最高学历")]),
                _c("p", [_vm._v(_vm._s(this.lawer.education))]),
              ]),
              _c("div", [
                this.lawer.gender === "女"
                  ? _c("i", { staticClass: "iconfont icon-nansheng" })
                  : _vm._e(),
                !(this.lawer.gender === "女")
                  ? _c("i", { staticClass: "iconfont icon-xingbie" })
                  : _vm._e(),
                _c("p", { staticClass: "SuSong" }, [_vm._v("性别")]),
                _c("p", [
                  _vm._v(
                    _vm._s(this.lawer.gender ? this.lawer.gender : "未知")
                  ),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "all" }, [
            _c("span", { on: { click: _vm.aller } }, [
              _vm._v(_vm._s(this.SuoYou)),
            ]),
            _c("i", {
              staticClass: "iconfont icon-gengduo-copy",
              on: { click: _vm.aller },
            }),
            _vm.timer
              ? _c(
                  "ul",
                  { staticClass: "date" },
                  _vm._l(_vm.date, function (item, index) {
                    return _c(
                      "li",
                      {
                        key: index,
                        style: { display: item === _vm.SuoYou ? "none" : "" },
                        on: {
                          click: function ($event) {
                            _vm.pop = item
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(item) + " ")]
                    )
                  }),
                  0
                )
              : _vm._e(),
          ]),
          _c("loading", { attrs: { showflag: _vm.like } }),
          _vm._l(_vm.mainArray, function (item, index) {
            return _c(
              "ul",
              {
                key: index,
                staticClass: "case",
                style: {
                  display:
                    item.data[0].graph.datas.length > 0 ? "block" : "none",
                },
              },
              [
                item.data[0].graph.datas.length > 0
                  ? _c(
                      "span",
                      [
                        _c("li", [_c("p", [_vm._v(_vm._s(item.title))])]),
                        _c(
                          "li",
                          [
                            _c(item.type, {
                              tag: "component",
                              attrs: {
                                settings: item.chartSettings,
                                series: item.chartSettings.series,
                                legend: item.chartSettings.legend,
                                colors: ["#4B9EFB", "#2DC1E7", "#C0DCFB"],
                                tooltip: item.chartSettings.tooltip,
                                "tooltip-visible": true,
                                grid: item.chartSettings.grid,
                                xAxis: item.chartSettings.xAxis,
                                yAxis: item.chartSettings.yAxis,
                                data: item.chartSettings,
                                "legend-visible":
                                  item.chartSettings.legend_visible,
                                events: item.chartSettings.event,
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._l(
                          typeof item.showflag == "boolean"
                            ? item.showflag
                              ? item.data[1].data
                              : item.data[1].data.slice(0, 3)
                            : [],
                          function (items, num) {
                            return _c(
                              "li",
                              { key: num, staticClass: "Court" },
                              [
                                _c("div", [
                                  _c("span", [_vm._v(_vm._s(items.num))]),
                                  _c(
                                    "span",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.caselaw(items.key)
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(items.name))]
                                  ),
                                ]),
                              ]
                            )
                          }
                        ),
                        item.data[1].data.length > 3 &&
                        typeof item.showflag == "boolean"
                          ? _c(
                              "div",
                              {
                                staticClass: "more",
                                staticStyle: {
                                  position: "relative",
                                  "z-index": "12",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.changeflag(index)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      item.showflag ? "收起" : "查看全部"
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      2
                    )
                  : _vm._e(),
              ]
            )
          }),
          _c("div", { staticClass: "newCase" }, [
            _vm._m(0),
            _c(
              "ul",
              [
                _vm._l(
                  _vm.display ? _vm.caselawe : _vm.caselawe.slice(0, 3),
                  function (item, index) {
                    return _c("li", { key: index }, [
                      _c(
                        "p",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.encnaj(item.doc_id)
                            },
                          },
                        },
                        [_vm._v(_vm._s(item.title))]
                      ),
                      _c("div", { staticClass: "list" }, [
                        _c("p", [_vm._v(_vm._s(item.court.trim()))]),
                        _c("p", [_vm._v("/" + _vm._s(item.case_id.trim()))]),
                        _c("p", [_vm._v("/" + _vm._s(item.pub_date.trim()))]),
                      ]),
                    ])
                  }
                ),
                _vm.caselawe.length > 3
                  ? _c(
                      "div",
                      {
                        on: {
                          click: function ($event) {
                            _vm.display = !_vm.display
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.display ? "收起" : "查看更多") + " "
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              2
            ),
          ]),
        ],
        2
      ),
      _vm.encounter
        ? _c(
            "div",
            { staticClass: "enclose" },
            [
              _c("precedent", {
                attrs: { id: _vm.scan },
                on: { changeflag: _vm.scandal },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("filter-case", {
        attrs: { conditions: _vm.cont_contaion },
        on: { submit: _vm.submit },
        model: {
          value: _vm.show,
          callback: function ($$v) {
            _vm.show = $$v
          },
          expression: "show",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "See" }, [_c("p", [_vm._v("最新判例")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }