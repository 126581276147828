<template>
  <div class="lawer_lvsuo">
    <div class="lawer_content">
      <div class="ShaiXuan">
        <i class="iconfont icon-back" @click="goback"></i>
        <div>
          <span @click="show = true">筛选</span>
        </div>
      </div>
      <ul class="portrait">
        <li>
          <img
            :src="this.$store.state.user.userInfo.avatarUrl"
            style="width:100%;height:100%;border-radius:50%"
          />
        </li>
        <li>
          <div>
            <span>{{ this.$route.query.name }}</span>
            <span>律师</span>
          </div>
          <div>
            <span>执业证号：{{ this.lawer.jobNumber }}</span>
          </div>
        </li>
      </ul>
      <ul class="portrait_lawer">
        <li>
          <span>{{ this.$route.query.lawFirmName }}</span>
        </li>
        <li>
          <div>
            <i class="iconfont icon-susongguanli"></i>
            <p class="SuSong">诉讼</p>
            <p>{{ this.lawer.superiorityNum }}件</p>
          </div>
          <div>
            <i class="iconfont icon-working-year"></i>
            <p class="SuSong">经验</p>
            <p>{{ this.lawer.Particular ? this.lawer.Particular : 0 }}年</p>
          </div>
          <div>
            <i class="iconfont icon-xueli"></i>
            <p class="SuSong">最高学历</p>
            <p>{{ this.lawer.education }}</p>
          </div>
          <div>
            <i
              class="iconfont icon-nansheng"
              v-if="this.lawer.gender === '女'"
            ></i>
            <i
              class="iconfont icon-xingbie"
              v-if="!(this.lawer.gender === '女')"
            ></i>
            <p class="SuSong">性别</p>
            <p>{{ this.lawer.gender ? this.lawer.gender : '未知' }}</p>
          </div>
        </li>
      </ul>
      <div class="all">
        <span @click="aller">{{ this.SuoYou }}</span>
        <i class="iconfont icon-gengduo-copy" @click="aller"></i>
        <ul class="date" v-if="timer">
          <li
            v-for="(item, index) in date"
            :key="index"
            @click="pop = item"
            :style="{ display: item === SuoYou ? 'none' : '' }"
          >
            {{ item }}
          </li>
        </ul>
      </div>
      <loading :showflag="like"></loading>
      <ul
        class="case"
        v-for="(item, index) in mainArray"
        :key="index"
        :style="{
          display: item.data[0].graph.datas.length > 0 ? 'block' : 'none'
        }"
      >
        <span v-if="item.data[0].graph.datas.length > 0">
          <li>
            <p>{{ item.title }}</p>
          </li>
          <li>
            <component
              :is="item.type"
              :settings="item.chartSettings"
              :series="item.chartSettings.series"
              :legend="item.chartSettings.legend"
              :colors="['#4B9EFB', '#2DC1E7', '#C0DCFB']"
              :tooltip="item.chartSettings.tooltip"
              :tooltip-visible="true"
              :grid="item.chartSettings.grid"
              :xAxis="item.chartSettings.xAxis"
              :yAxis="item.chartSettings.yAxis"
              :data="item.chartSettings"
              :legend-visible="item.chartSettings.legend_visible"
              :events="item.chartSettings.event"
            ></component>
          </li>
          <li
            class="Court"
            v-for="(items, num) in typeof item.showflag == 'boolean'
              ? item.showflag
                ? item.data[1].data
                : item.data[1].data.slice(0, 3)
              : []"
            :key="num"
          >
            <div>
              <span>{{ items.num }}</span>
              <span @click="caselaw(items.key)">{{ items.name }}</span>
            </div>
          </li>
          <div
            class="more"
            style="position:relative;z-index:12;"
            @click="changeflag(index)"
            v-if="
              item.data[1].data.length > 3 && typeof item.showflag == 'boolean'
            "
          >
            {{ item.showflag ? '收起' : '查看全部' }}
          </div>
        </span>
      </ul>

      <div class="newCase">
        <div class="See">
          <p>最新判例</p>
        </div>
        <ul>
          <li
            v-for="(item, index) in display ? caselawe : caselawe.slice(0, 3)"
            :key="index"
          >
            <p @click="encnaj(item.doc_id)">{{ item.title }}</p>
            <div class="list">
              <p>{{ item.court.trim() }}</p>
              <p>/{{ item.case_id.trim() }}</p>
              <p>/{{ item.pub_date.trim() }}</p>
            </div>
          </li>
          <div @click="display = !display" v-if="caselawe.length > 3">
            {{ display ? '收起' : '查看更多' }}
          </div>
        </ul>
      </div>
    </div>
    <div v-if="encounter" class="enclose">
      <precedent :id="scan" @changeflag="scandal"></precedent>
    </div>
    <filter-case
      v-model="show"
      :conditions="cont_contaion"
      @submit="submit"
    ></filter-case>
  </div>
</template>
<script>
import { caseBase, pydataBase } from '~api-config'
import VeRing from 'v-charts/lib/ring.common'
import VeLine from 'v-charts/lib/line.common'
import VeHistogram from 'v-charts/lib/histogram.common'
import FilterCase from './infoCompoments/filtercase'
import loading from '@/pages/Search/compoments/loading'
import precedent from '@/pages/precedentDetail'
import { setTimeout } from 'timers'
import { Promise } from 'q'
export default {
  components: {
    VeRing: VeRing,
    VeHistogram: VeHistogram,
    VeLine: VeLine,
    FilterCase: FilterCase,
    loading: loading,
    precedent: precedent
  },
  data() {
    return {
      pop: '',
      show: false,
      mainArray: [],
      lawer: {},
      caselawe: [],
      display: false,
      SuoYou: '全部',
      like: 1,
      date: [],
      timer: false,
      encounter: false,
      scan: null,
      cont_contaion: []
    }
  },
  watch: {
    pop(val) {
      const totast = this.$toast({
        mask: true,
        type: 'loading',
        message: '加载中...',
        duration: 0
      })
      this.SuoYou = val
      this.getData(
        this.$route.query.name,
        this.$route.query.lawFirmName,
        val === '全部' ? '' : val
      )
        .then(res => {
          totast.clear()
          this.resolveData(res)
        })
        .catch(err => {
          totast.clear()
          Promise.reject(err)
        })
    }
  },
  methods: {
    submit(key) {
      const totast = this.$toast({
        mask: true,
        type: 'loading',
        message: '加载中...',
        duration: 0
      })
      /* ?name=${this.$route.query.name}&condition=${key}&office=${this.$route.query.lawFirmName} */
      this.$axios
        .post(`${pydataBase}/api/lawyer/card/his`, {
          name: this.$route.query.name,
          condition: key,
          office: this.$route.query.lawFirmName
        })
        .then(res => {
          totast.clear()
          if (res.data.result.length === 0) {
            this.$notify('暂无判例列表')
          } else {
            this.$router.push({
              path: '/currson/caselaw',
              query: {
                name: this.$route.query.name,
                office: this.$route.query.lawFirmName,
                condition: key
              }
            })
          }
        })
    },
    encnaj(id) {
      this.scan = id
      this.encounter = true
    },
    scandal() {
      this.encounter = false
    },
    all() {
      this.getData(this.$route.query.name, this.$route.query.lawFirmName)
        .then(this.resolveData)
        .catch(err => {
          Promise.reject(err)
        })
    },
    changeflag(index) {
      this.$set(
        this.mainArray[index],
        'showflag',
        !this.mainArray[index].showflag
      )
    },
    caselaw(key) {
      const totast = this.$toast({
        mask: true,
        type: 'loading',
        message: '加载中...',
        duration: 0
      })
      /* ?name=${this.$route.query.name}&condition=${key}&office=${this.$route.query.lawFirmName} */
      this.$axios
        .post(`${pydataBase}/api/lawyer/card/his`, {
          name: this.$route.query.name,
          condition: key,
          office: this.$route.query.lawFirmName
        })
        .then(res => {
          totast.clear()
          if (res.data.result.length === 0) {
            this.$notify('暂无判例列表')
          } else {
            this.$store.state.caseDetail.lawer.date = this.SuoYou
            this.$store.state.caseDetail.lawer.scroll = document.querySelector(
              '.lawer_lvsuo'
            ).scrollTop
            this.$store.state.caseDetail.lawer.offsetHeight = document.querySelector(
              '.lawer_lvsuo'
            ).offsetHeight
            this.$router.push({
              path: '/currson/caselaw',
              query: {
                name: this.$route.query.name,
                office: this.$route.query.lawFirmName,
                condition: key
              }
            })
          }
        })
        .catch(err => {
          totast.clear()
          Promise.reject(err)
        })
    },
    aller() {
      this.timer = !this.timer
      const fn = e => {
        var haha = document.querySelector('.all')
        if (haha) {
          if (!haha.contains(e.target)) {
            this.timer = false
          }
        }
        document.body.removeEventListener('click', fn)
      }
      setTimeout(() => {
        document.body.addEventListener('click', fn)
      })
    },
    goback() {
      this.$router.go(-1)
    },
    getData(name, office, year = '') {
      /* ?name=${name}&office=${office}&year=${year} */
      if (year) {
        return this.$axios.post(`${pydataBase}/api/lawyer/card`, {
          name: name,
          office: office,
          year: year
        })
      } else {
        return this.$axios.post(`${pydataBase}/api/lawyer/card`, {
          name: name,
          office: office
        })
      }
    },
    resolveData(res) {
      const mainarr = res.data.result.data.data
      this.cont_contaion = res.data.result.conditions
      this.lawer.superiorityNum = res.data.result.data.total_nums
      this.caselawe = res.data.result.news
      this.caselawe.forEach(item => {})
      if (this.date.length === 0) {
        this.date = res.data.result.year
        this.date.unshift('全部')
      }
      mainarr.forEach((item, index, array) => {
        array[index].type =
          index === 7 ? VeHistogram : index === 8 ? VeLine : VeRing
        let sum = 0
        // v-charts图表绑定事件
        const legendselectchanged = title => {}
        if (array[index].type === VeRing) {
          // 当数组顺序 除  3，4，7 的环图
          this.params_charts = {
            name: '10000',
            legend: {
              orient: 'vertical',
              x: '35%',
              y: '25%',
              icon: 'circle',
              textStyle: {
                color: '#333333',
                fontSize: 14,
                fontWeight: 500
              },
              formatter(val) {
                return val
              }
            },
            series: {
              type: 'pie',
              radius: ['48%', '60%'],
              center: ['50%', '30%'],
              data: [],
              label: {
                position: 'center',
                show: false,
                fontSize: 14,
                color: '#333333',
                formatter: function(val, index) {
                  if (val.dataIndex === 0) {
                    return '总计' + sum / 2
                  } else {
                    return ''
                  }
                }
              }
            },
            labelLine: {
              smooth: true,
              length: 10,
              length2: 7
            },
            event: {
              legendselectchanged: legendselectchanged(item.title)
            }
          }
          // 相加之和
          item.data[0].graph.datas.forEach(function(item) {
            sum += item
          })
          // v-charts图表数据 +  name（ 名称 +百分比）
          item.data[0].graph.datas.slice(0, 3).forEach((items, num) => {
            this.params_charts.series.data.push({
              value: items,
              name:
                item.data[0].graph.name[num] +
                parseInt((items / sum) * 100) +
                '%'
            })
          })
          // 当环图中为2个时的样式
          if (item.data[0].graph.datas.length === 2) {
            this.params_charts.legend.y = '23%'
          } else if (item.data[0].graph.datas.length > 2) {
            // 当环图中为三个时的样式
            this.params_charts.legend.y = '21%'
            this.params_charts.legend.x = '32%'
          }
          // 返还params_charts
          array[index].chartSettings = this.params_charts
          // 当环图中的字数较少时的样式 选中数组为
          if (index > 6) {
            array[0].chartSettings.legend.x = '36%'
            array[5].chartSettings.legend.x = '36%'
          }
        } else if (array[index].type === VeHistogram) {
          // 柱状图
          this.params_charts = {
            color: ['#4B9EFB'],
            grid: {
              left: '3%',
              right: '0%',
              top: '23',
              containLabel: true,
              height: 200,
              width: '90%'
            },
            xAxis: [
              {
                type: 'category',
                data: [],
                axisTick: {
                  alignWithLabel: true
                }
              }
            ],
            yAxis: [
              {
                type: 'value'
              }
            ],
            series: [
              {
                name: '直接访问',
                type: 'bar',
                data: [],
                barWidth: 20,
                itemStyle: {
                  barBorderRadius: [70, 70, 70, 70, 70]
                },
                label: {
                  normal: {
                    show: true,
                    position: 'top',
                    textStyle: {
                      color: '#4B9EFB',
                      fontSize: 14,
                      backgroundColor: 'white'
                    },
                    formatter(val) {
                      return ((val.value / sum) * 100).toFixed(2) + '%'
                    }
                  }
                }
              }
            ],
            event: {
              click: legendselectchanged(item.title)
            }
          }

          item.data[0].graph.name.forEach((items, num) => {
            sum += item.data[0].graph.datas[num]
            this.params_charts.xAxis[0].data.push(items)
            this.params_charts.series[0].data.push(
              item.data[0].graph.datas[num]
            )
          })
          array[index].chartSettings = this.params_charts
        } else if (array[index].type === VeLine) {
          // 折线图
          this.params_charts = {
            columns: ['年份', '件数'],
            tooltip: {
              formatter: '{c}'
            },
            rows: [],
            grid: {
              left: '3%',
              right: '0%',
              top: '20',
              containLabel: true,
              height: 200,
              width: '90%'
            },
            legend_visible: false,
            event: {
              click: legendselectchanged(item.title)
            }
          }
          item.data[0].graph.datas.slice(0, 3).forEach((items, num) => {
            this.params_charts.rows.push({
              年份: item.data[0].graph.name[num].toString(),
              件数: Number(items)
            })
          })
          array[index].chartSettings = this.params_charts
        }
        // 拦截对手律所，实体法条，程序法条
        if (
          item.title === '对手律所' ||
          item.title === '实体法条' ||
          item.title === '程序法条'
        ) {
          this.params_charts = {
            tooltip: {
              trigger: 'axis'
            },
            legend: {
              orient: 'vertical',
              x: '50%',
              y: '17%',
              icon: 'circle',
              textStyle: {
                color: '#333333',
                fontSize: 12,
                fontWeight: 500
              },
              formatter(val) {
                // 律师报表 程序法条 超过26个字以省略号显示
                val = val.slice(0, 26)
                const arr = []
                for (let i = 0; i < val.length / 9; i++) {
                  arr.push(val.slice(i * 9, i * 9 + 9))
                }
                val = arr.join('\r\n')
                if (val.length > 25) {
                  return val + '...'
                } else {
                  return val
                }
              }
            },
            series: {
              type: 'pie',
              radius: ['33%', '45%'],
              center: ['25%', '33%'],
              data: [],
              label: {
                position: 'center',
                show: true,
                fontSize: 16,
                color: '#333333',
                formatter: function(val, index) {
                  if (val.dataIndex === 0) {
                    return '总计' + sum
                  } else {
                    return ''
                  }
                }
              },
              labelLine: {
                smooth: true,
                length: 10,
                length2: 7
              }
            }
          }
          // 相加之和
          item.data[0].graph.datas.forEach(function(item) {
            sum += item
          })
          sum /= 2
          // v-charts图表数据 +  name（ 名称 +百分比）
          item.data[0].graph.datas.slice(0, 3).forEach((items, num) => {
            this.params_charts.series.data.push({
              value: items,
              name:
                item.data[0].graph.name[num] +
                '\t' +
                parseInt((items / sum) * 100) +
                '%'
            })
          })
          if (item.data[0].graph.datas.length === 1) {
            this.params_charts.legend.y = '30%'
          } else if (item.data[0].graph.datas.length === 3) {
            this.params_charts.legend.y = '10%'
          }
          array[index].chartSettings = this.params_charts
        }
      })
      this.mainArray = mainarr
      this.$set(this.mainArray[0], 'showflag', false)
      this.$set(this.mainArray[1], 'showflag', false)
      this.$set(this.mainArray[2], 'showflag', false)
      this.$set(this.mainArray[3], 'showflag', false)
      this.$set(this.mainArray[4], 'showflag', false)
      this.$set(this.mainArray[5], 'showflag', false)
      this.$set(this.mainArray[6], 'showflag', false)
      this.$set(this.mainArray[7], 'showflag', false)
      this.$set(this.mainArray[8], 'showflag', false)
      this.$set(this.mainArray[9], 'showflag', false)
      this.$set(this.mainArray[10], 'showflag', false)
      this.like = 2
    }
  },
  mounted() {
    this.getData(this.$route.query.name, this.$route.query.lawFirmName)
      .then(res => {
        this.resolveData(res)
      })
      .catch(err => {
        Promise.reject(err)
      })
    this.$axios
      .get(
        `${caseBase}/lts/lawyer/queryBylawyerId?lawyerId=${
          this.$route.query.lawyerId
        }`
      )
      .then(res => {
        this.lawer = res.data.data
        const Particular =
          new Date().getFullYear() - this.lawer.jobNumber.slice(5, 9)
        this.lawer.Particular = Particular
        setTimeout(() => {
          if (this.$store.state.caseDetail.lawer.scroll) {
            document.querySelector(
              '.lawer_lvsuo'
            ).scrollTop = this.$store.state.caseDetail.lawer.scroll
            this.$store.state.caseDetail.lawer.scroll = null
          }
        }, 300)
      })
  },
  created() {
    if (this.$store.state.caseDetail.lawer.date) {
      this.SuoYou = this.$store.state.caseDetail.lawer.date
    }
  }
}
</script>

<style lang="stylus">
.lawer_lvsuo
  width 100%
  height 100%
  overflow-x hidden
  position relative
  .enclose
    width 100%
    height 100%
    position fixed
    left 0px
    top 0px
    background #ffffff
    z-index 13
  .lawer_content
    width 92%
    margin 10px auto 0px
    padding-bottom 100px
    .ShaiXuan
      margin-top 10px
      width 100%
      display flex
      justify-content space-between
      height 20px
      span:nth-child(1)
        margin-right 20px
      span
        color #4B9EFB
        font-size 15px
        line-height 20px
    .all
      width 20%
      height 20px
      line-height 20px
      margin-top 10px
      text-align right
      font-size 15px
      color #666666
      position relative
      z-index 11
      float right
      .date
        width 118px
        background rgba(255, 255, 255, 1)
        box-shadow 0px 0px 8px 3px rgba(240, 240, 240, 1)
        border-radius 10px
        position absolute
        top 35px
        right 0px
        text-align center
        font-size 15px
        color #666666
        p
          height 35px
          line-height 35px
        li
          height 35px
          line-height 35px
      span
        margin-right 5px
      i
        vertical-align 2px
        color #333333
    .portrait_lawer
      width 100%
      height 133px
      border-radius 10px
      box-shadow 0px 10px 25px 0px rgba(232, 232, 232, 1)
      margin-top 10px
      .icon-xingbie
        color #7c83c4 !important
      .SuSong
        font-size 12px
      li:nth-child(1)
        width 100%
        height 47px
        border-bottom 1px solid #CCCCCC
        line-height 47px
        text-indent 22px
        color #666666
        font-size 14px
      li:nth-child(2)
        width 85%
        display flex
        justify-content space-between
        margin 20px auto 0px
        color #666666
        font-size 14px
        div:nth-child(1)
          i
            color #6C58FF
        div:nth-child(2)
          i
            color #509FFD
        div:nth-child(3)
          i
            color #FC9132
        div:nth-child(4)
          i
            color #FF7D92
    .newCase
      width 100%
      padding-bottom 20px
      margin-top 20px
      .See
        display flex
        justify-content space-between
        font-size 15px
        p:nth-child(1)
          color #333333
        p:nth-child(2)
          color #4B9EFB
          font-size 13px
      ul
        font-size 15px
        color #000000
        div
          text-align center
          color #999999
          margin-top 5px
        li
          font-size 15px
          margin-top 10px
          overflow hidden
          .list
            color #666666
            font-size 13px
            margin-top 5px
            width 100%
            font-size 12px
            p:nth-child(1)
              max-width 27%
              height 20px
              white-space nowrap
              text-overflow ellipsis
              overflow hidden
              float left
            p:nth-child(2)
              max-width 50%
              height 20px
              white-space nowrap
              text-overflow ellipsis
              overflow hidden
              float left
            p:nth-child(3)
              max-width 22%
              height 20px
              white-space nowrap
              text-overflow ellipsis
              overflow hidden
              float left
    .case
      width 100%
      margin-top 50px
      box-shadow 0px 0px 8px 1px rgba(204, 204, 204, 0.5)
      border-radius 5px
      padding-bottom 10px
      border 1px solid transparent
      li:nth-child(1)
        width 90%
        margin 0px auto
        height 25px
        line-height 25px
        display flex
        justify-content space-between
        font-size 15px
        margin-top 10px
        p:nth-child(1)
          color #333333
        p:nth-child(2)
          color #4B9EFB
      li:nth-child(2)
        width 100%
        height 250px
      .Court
        position relative
        font-size 15px
        width 90%
        margin 0px auto
        div
          margin-top 10px
          display flex
          justify-content space-between
          span:nth-child(1)
            color #4B9EFB
            margin-left 20px
            display block
            width 20%
          span:nth-child(2)
            color #333333
            display block
            width 80%
      .more
        color #999999
        font-size 15px
        text-align center
        margin-top 15px
    .portrait
      width 100%
      height 65px
      li:nth-child(1)
        width 65px
        height 65px
        float left
      li:nth-child(2)
        width calc(100% - 90px)
        height 65px
        float left
        margin-left 10px
        margin-top 10px
        div:nth-child(1)
          font-size 17px
          color #333333
          span:nth-child(2)
            color #666666
            font-size 12px
            margin-left 7px
        div:nth-child(2)
          color #666666
          font-size 12px
          margin-top 5px
</style>
